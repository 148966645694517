import React from 'react';

import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import './style.scss';

import BlockDarkWrapper from 'components/dark_wrapper';
import BlockPortfolioItem from 'components/portfolio_item';

import image_latvijas_radio_image_1 from 'assets/images/projects/ios/latvijas_radio/image_1.webp';
import image_swipo_image_1 from 'assets/images/projects/ios/swipo/image_1.webp';
import image_pasazieru_vilciens_image_7 from 'assets/images/projects/ios/pasazieru_vilciens/image_7.webp';
import image_leta_station_image_1 from 'assets/images/projects/ios/leta_station/phone/image_1.webp';
import image_agerona_image_1 from 'assets/images/projects/ios/agerona/image_1.webp';

class PortfolioIos extends React.Component {
  
  render() {
    const PORTFOLIO_CATEGORY_IOS_ID = 'ios';

    this.props.onRender(PORTFOLIO_CATEGORY_IOS_ID);

    return (
      <div className="container">

        <BlockDarkWrapper
          contentComponent={this.content()}
          accountForScrolling="true" />

      </div>
    );
  }

  content() {
    return (
      <div className="wrapper-simplebar" 
        data-simplebar
        data-simplebar-auto-hide="false">
        <div className="row">
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_latvijas_radio_image_1}
              title="Latvijas Radio"
              urlTitle="AppStore"
              url="https://apps.apple.com/lv/app/latvijas-radio/id1607281520" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_swipo_image_1}
              title="Swipo"/>

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_pasazieru_vilciens_image_7}
              title="Pasažieru Vilciens (support only)"
              urlTitle="AppStore"
              url="https://apps.apple.com/lv/app/pasa%C5%BEieru-vilciens/id663337208" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_leta_station_image_1}
              title="Station Media Monitoring"
              urlTitle="AppStore"
              url="https://apps.apple.com/us/app/station-media-monitoring/id1361211902" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_agerona_image_1}
              title="Agerona"
              urlTitle="AppStore"
              url="https://apps.apple.com/us/app/agerona/id1236431276" />

          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioIos;