import React from 'react';

import './style.scss';
import {
  NavLink,
} from "react-router-dom";

import image_logo from 'assets/images/initials_black.png';

class Header extends React.Component {

  render() {
    return (
      <header className="layout-header">
        <div className="container">
          <nav>
            <ul>
              <li>
                <NavLink to="/about-me" activeClassName="active">Par mani</NavLink>
              </li>
              <li className="logo">
                <NavLink to="/">
                  <img src={image_logo} alt="logo" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/portfolio" activeClassName="active">Portfolio</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;