import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect
} from "react-router-dom";

import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import './style.scss';
import Header from 'components/header';
import PortfolioWeb from './web';
import PortfolioAndroid from './android';
import PortfolioIos from './ios';
import PortfolioWindows from './windows';
import PortfolioMacos from './macos';

var portfolioInitialRenderDone = false;

class Portfolio extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      activePortfolioCategoryId: '',
    };
  }

  render() {
    var portfolioCategory = this.state.activePortfolioCategoryId;

    // if (this.state.activePortfolioCategoryId === '') {
    //   if (window.location.pathname === '/portfolio/web') {
    //     portfolioCategory = 'web';
    //   }
    //   if (window.location.pathname === '/portfolio/android') {
    //     portfolioCategory = 'android';
    //   }
    //   if (window.location.pathname === '/portfolio/ios') {
    //     portfolioCategory = 'ios';
    //   }
    //   if (window.location.pathname === '/portfolio/windows') {
    //     portfolioCategory = 'windows';
    //   }
    //   if (window.location.pathname === '/portfolio/macos') {
    //     portfolioCategory = 'macos';
    //   }
    // }

    return (
      <div className={'layout-page-portfolio ' + portfolioCategory}>

        <Header />

        <Router>
          <div className="block-submenu">
            <div className="container">
              <nav>
                <ul>
                  <li>
                    <NavLink to="/portfolio/web" activeClassName="active">Web</NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio/android" activeClassName="active">Android</NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio/ios" activeClassName="active">iOS</NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio/windows" activeClassName="active">Windows</NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio/macos" activeClassName="active">macOS</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <Switch>
            <Route path="/portfolio/web">
              <PortfolioWeb
                onRender={(portfolioCategoryId) => this.handleOnRender(portfolioCategoryId)} />
            </Route>
            <Route path="/portfolio/android">
              <PortfolioAndroid
                onRender={(portfolioCategoryId) => this.handleOnRender(portfolioCategoryId)} />
            </Route>
            <Route path="/portfolio/ios">
              <PortfolioIos
                onRender={(portfolioCategoryId) => this.handleOnRender(portfolioCategoryId)} />
            </Route>
            <Route path="/portfolio/windows">
              <PortfolioWindows
                onRender={(portfolioCategoryId) => this.handleOnRender(portfolioCategoryId)} />
            </Route>
            <Route path="/portfolio/macos">
              <PortfolioMacos
                onRender={(portfolioCategoryId) => this.handleOnRender(portfolioCategoryId)} />
            </Route>
            <Route path="/portfolio">
              <Redirect to='/portfolio/web'/>;
            </Route>
          </Switch>
        </Router>

      </div>
    );
  }

  handleOnRender(portfolioCategoryId) {
    //console.log('handleOnRender: ', portfolioCategoryId);

    if (portfolioInitialRenderDone === false) {
      portfolioInitialRenderDone = true;
    } else {
      if (this.state.activePortfolioCategoryId !== portfolioCategoryId) {
        // this will cause re-render of current component
        this.setState({
          activePortfolioCategoryId: portfolioCategoryId,
        });
      }
    }
  }
}

export default Portfolio;