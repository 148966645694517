import React from 'react';

import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import './style.scss';

import BlockDarkWrapper from 'components/dark_wrapper';
import BlockPortfolioItem from 'components/portfolio_item';

import image_swipo_image_1 from 'assets/images/projects/windows/swipo/image_1.webp';

class PortfolioWindows extends React.Component {
  
  render() {
    const PORTFOLIO_CATEGORY_WINDOWS_ID = 'windows';

    this.props.onRender(PORTFOLIO_CATEGORY_WINDOWS_ID);

    return (
      <div className="container">

        <BlockDarkWrapper
          contentComponent={this.content()}
          accountForScrolling="true" />

      </div>
    );
  }

  content() {
    return (
      <div className="wrapper-simplebar" 
        data-simplebar
        data-simplebar-auto-hide="false">
        <div className="row">
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_swipo_image_1}
              title="Swipo"/>

          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioWindows;