import React from 'react';

import './style.scss';

class BlockDarkWrapper extends React.Component {

  render() {
    return (
      <div className="block-dark-wrapper" account-for-scrolling={this.props.accountForScrolling}>
        {this.props.contentComponent}
      </div>
    );
  }
}

export default BlockDarkWrapper;