import React from 'react';

import './style.scss';
import Header from 'components/header';
import BlockDarkWrapper from 'components/dark_wrapper';

class AboutMe extends React.Component {

  render() {
    return (
      <div className="layout-page-about-me">

        <Header />

        <div className="container">

          <BlockDarkWrapper
            contentComponent={this.content()}
            accountForScrolling="true" />

        </div>
      </div>
    );
  }

  content() {
    return (
      <div className="wrapper-simplebar" 
        data-simplebar
        data-simplebar-auto-hide="false">
        <div className="row">
          <div className="col-24 col-md-12">

            <h2>Karjera</h2>

            <div className="typography-style">
              <BlockDarkWrapper
                contentComponent={this.profession()} />

              <BlockDarkWrapper
                contentComponent={this.education()} />

              <BlockDarkWrapper
                contentComponent={this.workExperience()} />

              <BlockDarkWrapper
                contentComponent={this.contactInformation()} />
            </div>

          </div>
          <div className="col-24 col-md-12">

            <h2>Tehniskās zināšanas</h2>

            <div className="typography-style">
              <BlockDarkWrapper
                contentComponent={this.webFrontend()} />

              <BlockDarkWrapper
                contentComponent={this.mobileApplications()} />

              <BlockDarkWrapper
                contentComponent={this.desktopApplications()} />

              <BlockDarkWrapper
                contentComponent={this.other()} />
            </div>

          </div>
        </div>
      </div>
    );
  }

  profession() {
    return (
      <div>
        <b>Profesija:</b>
        <br/>
        <br/>Web frontenda un mobilo/darbvirsmu aplikāciju izstrādātājs
      </div>
    )
  }

  education() {
    return (
      <div>
        <b>Izglītība:</b>
        <br/>
        <br/>Rīgas Tehniskā Universitāte
        <br/>Bakalaura grāds, Informācijas tehnoloģijas (2010-2014)
      </div>
    )
  }

  workExperience() {
    return (
      <div>
        <b>Darba pieredze:</b>
        <br/>
        <br/>
        <ul>
          <li>Goodday Group (no 2023.02 un turpinās)</li>
          <li>Mediaparks / Efumo (no 2014.09 līdz 2022.12, 8 gadi un 4 mēneši)</li>
          <li>Sem.lv (no 2012.08 līdz 2014.03, 1 gads 8 mēneši)</li>
        </ul>
      </div>
    )
  }

  contactInformation() {
    return (
      <div>
        <b>Kontaktinformācija:</b>
        <br/>
        <br/>
        <ul>
          <li>E-pasts: <a href="mailto:sandis.putnis@gmail.com">sandis.putnis@gmail.com</a></li>
          <li>LinkedIn: <a href="https://www.linkedin.com/in/sandis-putnis" target="_blank" rel="noreferrer">www.linkedin.com/in/sandis-putnis</a></li>
        </ul>
      </div>
    )
  }

  webFrontend() {
    return (
      <div>
        <b>Web frontends:</b>
        <br/>
        <br/>Veidoju HTML jaunām tīmekļa lapām vai veicu papildinājumus jau esošām. "Griežu" dizaina failus no Photoshop, Illustrator, Figma vai Sketch programmām.
        <br/>Kur vien varu, pielietoju vektoru grafikas.
        <br/>Veidoju responsīvu dizainu.
        <br/>HTML tiek veidots no pašiem pamatiem vai kādā templeitu ietvarā (piem. Twig, Smarty), kuri ir daļa no kādas CMS sistēmas - Symfony, Drupal, Wordpress vai citas pielāgotas sistēmas.
      </div>
    )
  }

  mobileApplications() {
    return (
      <div>
          <b>Mobilās aplikācijas:</b>
          <br/>
          <br/>Android - Java
          <br/>iOS - Swift (ir pieredze arī ar ObjC)
          <br/>
          <br/>Ir pieredze arī ar hibrīdaplikāciju platformām:
          <br/>
          <ul>
            <li>Nativescript (Angular/Typescript)</li>
          </ul>
      </div>
    )
  }

  desktopApplications() {
    return (
      <div>
        <b>Darbvirsmu aplikācijas:</b>
        <br/>
        <br/>Windows - C#
        <br/>macOS - Swift
      </div>
    )
  }

  other() {
    return (
      <div>
        Versionēšanu veicu ar GIT.
        <br/>"Dependency management" ar Gradle, NPM, Composer, Cocoapods.
        <br/>Sass/Js kompilēšana ar Gulp/Webpack/Compass/Node-sass.
        <br/>Strādāju uz dažādām operētājsistēmām (Windows, macOS, Linux) - atkarīgs no projekta.
      </div>
    )
  }
}

export default AboutMe;