import React from 'react';

import './style.scss';

class Footer extends React.Component {

  render() {
    return (
      <footer className="layout-footer">

      </footer>
    );
  }
}

export default Footer;