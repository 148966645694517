import React from 'react';

import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import './style.scss';

import BlockDarkWrapper from 'components/dark_wrapper';
import BlockPortfolioItem from 'components/portfolio_item';

import image_latvijas_radio_image_1 from 'assets/images/projects/android/latvijas_radio/image_1.webp';
import image_smart_mobile_image_1 from 'assets/images/projects/android/smart_mobile/image_1.webp';
import image_swipo_image_2 from 'assets/images/projects/android/swipo/image_2.webp';
import image_soso_kz_image_1 from 'assets/images/projects/android/soso_kz/image_1.webp';
import image_com_zaimionline_image_1 from 'assets/images/projects/android/com_zaimionline/image_1.webp';
import image_kz_microkredit24_image_1 from 'assets/images/projects/android/kz_microkredit24/image_1.webp';
import image_leta_station_image_1 from 'assets/images/projects/android/leta_station/mobile/image_1.webp';
import image_juras_prakse_image_4 from 'assets/images/projects/android/juras_prakse/image_4.webp';
import image_kro_wallet_image_1 from 'assets/images/projects/android/kro_wallet/image_1.webp';
import image_pasazieru_vilciens_image_7 from 'assets/images/projects/android/pasazieru_vilciens/image_7.webp';
import image_idcap_image_1 from 'assets/images/projects/android/idcap/image_1.webp';
import image_bms_image_1 from 'assets/images/projects/android/bms/image_1.webp';
import image_salaspils_image_2 from 'assets/images/projects/android/salaspils/image_2.webp';
import image_agerona_image_1 from 'assets/images/projects/android/agerona/image_1.webp';

class PortfolioAndroid extends React.Component {
  
  render() {
    const PORTFOLIO_CATEGORY_ANDROID_ID = 'android';

    this.props.onRender(PORTFOLIO_CATEGORY_ANDROID_ID);

    return (
      <div className="container">

        <BlockDarkWrapper
          contentComponent={this.content()}
          accountForScrolling="true" />

      </div>
    );
  }

  content() {
    return (
      <div className="wrapper-simplebar" 
        data-simplebar
        data-simplebar-auto-hide="false">
        <div className="row">
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_latvijas_radio_image_1}
              title="Latvijas Radio"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=lv.latvijasradio&hl=lv&gl=lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_smart_mobile_image_1}
              title="SmartMobile"/>

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_swipo_image_2}
              title="Swipo"/>

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_com_zaimionline_image_1}
              title="COM Zaimionline"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=com.app.zaimionlinecom" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_kz_microkredit24_image_1}
              title="KZ Microkredit24"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=kz.install.microkredit24kz" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_soso_kz_image_1}
              title="SOSO KZ"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=kz.soso&hl=kk&gl=kz" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_leta_station_image_1}
              title="Station Media Monitoring"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=efumo.station&hl=lv&gl=lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_juras_prakse_image_4}
              title="Jūras Prakse"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=efumo.onboard_training&hl=lv&gl=lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_kro_wallet_image_1}
              title="KroWallet"
              url="https://www.kro.money/" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_pasazieru_vilciens_image_7}
              title="Pasažieru Vilciens (support only)"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=lv.amberphone.pasazieruvilciens&hl=lv&gl=lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_idcap_image_1}
              title="IDCAP"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=efumo.idcap&hl=lv&gl=lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_bms_image_1}
              title="BMS" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_salaspils_image_2}
              title="Salaspils"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=efumo.salaspils&hl=lv&gl=lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_agerona_image_1}
              title="Agerona"
              urlTitle="PlayStore"
              url="https://play.google.com/store/apps/details?id=efumo.agerona&hl=lv&gl=lv" />

          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioAndroid;