import React from 'react';

import './style.scss';
import image_ic_code from 'assets/images/ic_code_white.svg';

class BlockPortfolioItem extends React.Component {

  render() {
    var finalImage = this.props.image;
    var underConstruction = '';

    if (finalImage === '') {
      finalImage = image_ic_code;
      underConstruction = 'under-construction';
    }

    var urlTitle = this.props.urlTitle;

    if (this.props.urlTitle == null) {
      urlTitle = this.props.url;
    }

    return (
      <div className="block-portfolio-item">
        <div className={'wrapperImage ' + underConstruction}
              style={{  
                backgroundImage: "url(" + finalImage + ")",
              }}>
        </div>
        <div className="wrapper-info">
          <div className="title">{this.props.title}</div>

          {this.props.url !== '' &&
            <div className="url">
              <a href={this.props.url} target="_blank" rel="noreferrer">{urlTitle}</a>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default BlockPortfolioItem;