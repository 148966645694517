import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './style.scss';
import Footer from 'components/footer/index';
import AboutMe from 'pages/about_me/index';
import Portfolio from 'pages/portfolio/index';

class App extends React.Component {

  componentDidMount(){
    document.title = 'Sandis Putnis';
  }

  render() {
    return (
      <div className="wrapper-page">
        <div className="wrapper-page-header-and-content">

          <Router>

            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}

            <Switch>
              <Route path="/portfolio">
                <Portfolio />
              </Route>
              <Route path="/about-me">
                <AboutMe />
              </Route>
              <Route path="/">
                <Redirect to='/about-me'/>;
              </Route>
            </Switch>
          </Router>

        </div>

        <Footer />

      </div>
    );
  }
}

export default App;