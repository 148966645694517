import React from 'react';

import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import './style.scss';

import BlockDarkWrapper from 'components/dark_wrapper';
import BlockPortfolioItem from 'components/portfolio_item';

import image_aiwaclinic_image_1 from 'assets/images/projects/web/aiwaclinic/image_1.jpg';
import image_aizdevums_image_1 from 'assets/images/projects/web/aizdevums/image_1.jpg';
import image_atrum_image_1 from 'assets/images/projects/web/atrum/image_1.jpg';
import image_augstspriegumu_tikls_image_1 from 'assets/images/projects/web/augstspriegumu_tikls/image_1.jpg';
import image_balcia_image_1 from 'assets/images/projects/web/balcia/image_1.jpg';
import image_bmcertification_image_1 from 'assets/images/projects/web/bmcertification/image_1.jpg';
import image_catchbox_image_1 from 'assets/images/projects/web/catchbox/image_1.jpg';
import image_citadel_image_1 from 'assets/images/projects/web/citadel/image_1.jpg';
import image_dlw_image_1 from 'assets/images/projects/web/dlw/image_1.jpg';
import image_drogas_image_1 from 'assets/images/projects/web/drogas/image_1.jpg';
import image_e_ligumi_image_1 from 'assets/images/projects/web/e_ligumi/image_1.jpg';
import image_efumo_image_1 from 'assets/images/projects/web/efumo/image_1.jpg';
import image_expobank_image_1 from 'assets/images/projects/web/expobank/image_1.jpg';
import image_fixarpro_image_1 from 'assets/images/projects/web/fixarpro/image_1.jpg';
import image_flymeaway_image_1 from 'assets/images/projects/web/flymeaway/image_1.jpg';
import image_grosauto_image_1 from 'assets/images/projects/web/grosauto/image_1.jpg';
import image_history_space_image_1 from 'assets/images/projects/web/history_space/image_1.jpg';
import image_iecava_image_1 from 'assets/images/projects/web/iecava/image_1.jpg';
import image_ievas_virtuve_image_1 from 'assets/images/projects/web/ievas_virtuve/image_1.jpg';
import image_jelgavas_poliklinika_image_1 from 'assets/images/projects/web/jelgavas_poliklinika/image_1.jpg';
import image_joker_image_1 from 'assets/images/projects/web/joker/image_1.jpg';
import image_jurmalas_dome_image_1 from 'assets/images/projects/web/jurmalas_dome/image_1.jpg';
import image_jurmalas_slimnica_image_1 from 'assets/images/projects/web/jurmalas_slimnica/image_1.jpg';
import image_kursi_image_1 from 'assets/images/projects/web/kursi/image_1.jpg';
import image_labakie_image_1 from 'assets/images/projects/web/labakie/image_1.jpg';
import image_latcredit_image_1 from 'assets/images/projects/web/latcredit/image_1.jpg';
import image_lateko_image_1 from 'assets/images/projects/web/lateko/image_1.jpg';
import image_latio_image_1 from 'assets/images/projects/web/latio/image_1.jpg';
import image_lnmm_image_1 from 'assets/images/projects/web/lnmm/image_1.jpg';
import image_luminos_image_1 from 'assets/images/projects/web/luminos/image_1.jpg';
import image_luminos_cloud_image_1 from 'assets/images/projects/web/luminos_cloud/image_1.jpg';
import image_lzvo_image_1 from 'assets/images/projects/web/lzvo/image_1.jpg';
import image_makroekonomika_image_1 from 'assets/images/projects/web/makroekonomika/image_1.jpg';
import image_manacope_image_1 from 'assets/images/projects/web/manacope/image_1.jpg';
import image_manapolise_image_1 from 'assets/images/projects/web/manapolise/image_1.jpg';
import image_manizurnali_image_1 from 'assets/images/projects/web/manizurnali/image_1.jpg';
import image_manpower_image_1 from 'assets/images/projects/web/manpower/image_1.jpg';
import image_manspasts_image_1 from 'assets/images/projects/web/manspasts/image_1.jpg';
import image_manspasts_new_image_1 from 'assets/images/projects/web/manspasts_new/image_1.jpg';
import image_mediaparks_image_1 from 'assets/images/projects/web/mediaparks/image_1.jpg';
import image_my_card_image_1 from 'assets/images/projects/web/my_card/image_1.jpg';
import image_naudasskola_image_1 from 'assets/images/projects/web/naudasskola/image_1.jpg';
import image_nekonference_image_1 from 'assets/images/projects/web/nekonference/image_1.jpg';
import image_ogre_image_1 from 'assets/images/projects/web/ogre/image_1.jpg';
import image_precos_image_1 from 'assets/images/projects/web/precos/image_1.jpg';
import image_psp_image_1 from 'assets/images/projects/web/psp/image_1.jpg';
import image_rd_ipasumu_departaments_image_1 from 'assets/images/projects/web/rd_ipasumu_departaments/image_1.jpg';
import image_rigasudens_image_1 from 'assets/images/projects/web/rigasudens/image_1.jpg';
import image_rigasziedi_image_1 from 'assets/images/projects/web/rigasziedi/image_1.jpg';
import image_rix_image_1 from 'assets/images/projects/web/rix/image_1.jpg';
import image_sadales_tikls_image_1 from 'assets/images/projects/web/sadales_tikls/image_1.jpg';
import image_salaspils_image_1 from 'assets/images/projects/web/salaspils/image_1.jpg';
import image_scomex_image_1 from 'assets/images/projects/web/scomex/image_1.jpg';
import image_sseriga_image_1 from 'assets/images/projects/web/sseriga/image_1.jpg';
import image_tad_image_1 from 'assets/images/projects/web/tad/image_1.jpg';
import image_tamro_image_1 from 'assets/images/projects/web/tamro/image_1.jpg';
import image_tani_credit_image_1 from 'assets/images/projects/web/tani_credit/image_1.jpg';
import image_viesturdarzs_image_1 from 'assets/images/projects/web/viesturdarzs/image_1.jpg';
import image_vni_image_1 from 'assets/images/projects/web/vni/image_1.jpg';
import image_wandoo_image_1 from 'assets/images/projects/web/wandoo/image_1.jpg';
import image_zip_credit_image_1 from 'assets/images/projects/web/zip_credit/image_1.jpg';
import image_zm_portal_image_1 from 'assets/images/projects/web/zm_portal/image_1.jpg';

class PortfolioWeb extends React.Component {
  
  render() {
    const PORTFOLIO_CATEGORY_WEB_ID = 'web';

    this.props.onRender(PORTFOLIO_CATEGORY_WEB_ID);

    return (
      <div className="container">

        <BlockDarkWrapper
          contentComponent={this.content()}
          accountForScrolling="true" />

      </div>
    );
  }

  content() {
    return (
      <div className="wrapper-simplebar" 
        data-simplebar
        data-simplebar-auto-hide="false">
        <div className="row">
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_aiwaclinic_image_1}
              title="Aiwaclinic"
              url="https://aiwaclinic.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_aizdevums_image_1}
              title="Aizdevums"
              url="https://www.aizdevums.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_atrum_image_1}
              title="Atrum"
              url="https://www.atrum.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_augstspriegumu_tikls_image_1}
              title="Augstspriegumu tīkls"
              url="https://ast.lv/lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_balcia_image_1}
              title="Balcia"
              url="https://balcia.lt/en" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_bmcertification_image_1}
              title="BMCertification"
              url="https://lv.bmcertification.com" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_catchbox_image_1}
              title="Catchbox"
              url="https://catchbox.com" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_citadel_image_1}
              title="Citadel"
              url="https://www.koprade.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_dlw_image_1}
              title="DLW"
              url="http://www.dlw.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_drogas_image_1}
              title="Drogas"
              url="https://www.drogas.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_e_ligumi_image_1}
              title="E-līgumi"
              url="" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_efumo_image_1}
              title="Efumo"
              url="https://www.efumo.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_expobank_image_1}
              title="Expobank"
              url="https://www.expobank.eu" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_fixarpro_image_1}
              title="Fixarpro"
              url="https://fixar.pro" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_flymeaway_image_1}
              title="Fly me away"
              url="http://www.flymeaway.lv/lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_grosauto_image_1}
              title="Grosauto"
              url="https://grosauto.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_history_space_image_1}
              title="History space"
              url="https://space.history.network/auth.aspx" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_iecava_image_1}
              title="Iecava"
              url="https://www.iecava.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_ievas_virtuve_image_1}
              title="Ievas virtuve" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_jelgavas_poliklinika_image_1}
              title="Jelgavas poliklīnika"
              url="https://jp.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_joker_image_1}
              title="Joker"
              url="https://www.joker.lv/spelu-zales" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_jurmalas_dome_image_1}
              title="Jūrmalas dome"
              url="https://www.jurmala.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_jurmalas_slimnica_image_1}
              title="Jūrmalas slimnīca"
              url="https://jurmalasslimnica.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_kursi_image_1}
              title="Kurši"
              url="https://www.kursi.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_labakie_image_1}
              title="Baltijas Biroju Serviss"
              url="https://labakie.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_latcredit_image_1}
              title="Latcredit"
              url="https://www.latcredit.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_lateko_image_1}
              title="Lateko"
              url="https://www.latekolizings.lv/lv/index" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_latio_image_1}
              title="Latio"
              url="http://latio.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_lnmm_image_1}
              title="LNMM"
              url="http://www.lnmm.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_luminos_image_1}
              title="Luminos"
              url="https://luminos.cloud" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_luminos_cloud_image_1}
              title="Luminos Cloud"
              url="https://luminos.cloud" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_lzvo_image_1}
              title="LZVO"
              url="https://lzvo.lv/lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_makroekonomika_image_1}
              title="Makroekonomika"
              url="https://www.makroekonomika.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_manacope_image_1}
              title="Mana cope"
              url="https://www.manacope.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_manapolise_image_1}
              title="Mana polise"
              url="https://www.manapolise.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_manizurnali_image_1}
              title="Mani žurnāli"
              url="https://www.manizurnali.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_manpower_image_1}
              title="Manpower"
              url="https://manpower.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_manspasts_image_1}
              title="Mans pasts"
              url="https://www.manspasts.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_manspasts_new_image_1}
              title="Mans pasts - New"
              url="https://new.manspasts.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_mediaparks_image_1}
              title="Mediaparks"
              url="https://www.mediaparks.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_my_card_image_1}
              title="My card" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_naudasskola_image_1}
              title="Naudas skola"
              url="https://www.naudasskola.lv/lv/expo" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_nekonference_image_1}
              title="Nekonference"
              url="http://www.nekonferences.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_ogre_image_1}
              title="Ogre"
              url="https://www.ogresnovads.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_precos_image_1}
              title="Precos"
              url="https://www.precos.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_psp_image_1}
              title="Pensiju fonds"
              url="https://pensijufonds.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_rd_ipasumu_departaments_image_1}
              title="Rīgas grausti"
              url="https://grausti.riga.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_rigasudens_image_1}
              title="Rīgas ūdens"
              url="https://www.rigasudens.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_rigasziedi_image_1}
              title="Rīgas ziedi"
              url="https://www.rigasziedi.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_rix_image_1}
              title="RIX"
              url="https://www.riga-airport.com" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_sadales_tikls_image_1}
              title="Sadales tīkls"
              url="https://www.sadalestikls.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_salaspils_image_1}
              title="Salaspils"
              url="https://salaspils.lv/lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_scomex_image_1}
              title="Directlee"
              url="" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_sseriga_image_1}
              title="SSE Riga"
              url="https://www.sseriga.edu" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_tad_image_1}
              title="TAD veikals"
              url="http://duniveikals.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_tamro_image_1}
              title="Tamro"
              url="https://www.tamrobaltics.com/lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_tani_credit_image_1}
              title="Tani credit"
              url="https://www.tanikredyt.pl" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_viesturdarzs_image_1}
              title="Viesturdārzs"
              url="https://merksviesturdarzs.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_vni_image_1}
              title="VNI"
              url="https://www.vni.lv" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_wandoo_image_1}
              title="Wandoo"
              url="https://wandoofinance.com" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_zip_credit_image_1}
              title="ZIP credit" />

          </div>
          <div className="col-24 col-md-8">

            <BlockPortfolioItem 
              image={image_zm_portal_image_1}
              title="Zemkopības ministrija"
              url="https://www.zm.gov.lv" />

          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioWeb;